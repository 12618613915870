import React from 'react'
import {navigate} from 'gatsby';
import {Location} from '@reach/router';
import {Form, Input, Button} from 'antd';
import {LocaleContext} from "../../../context/LocaleContext";
import language from "../../../../data/language.yaml";
import {post} from '../../../services/api';

const { TextArea } = Input;

class ContactForm extends React.Component {
    state = {
        allowSubmit: true,
        errors: [],
        showForm: true
    }

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    onSubmitCallback = (error, response) => {
        if (response.data.status === 'error') {
            this.setState({allowSubmit: true, errors: response.data.errors})
        } else if (response.data.status === 'success' && response.data.errors.length === 0) {
            this.setState({allowSubmit: false, showForm: false});
        }
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(this.onValidateCallback);
    }

    onValidateCallback = (errors, values) => {
        if (errors === null) {
            this.setState({allowSubmit: false});
            post('/wp-json/brew/v1/contact/', this.props.form.getFieldsValue(), this.onSubmitCallback);
        }
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        let lang = language.contactUs;
        let current = this.context.locale;

        let content = false;
        if (this.state.showForm) {
            content = <Form onSubmit={this.onSubmit}>
                { this.state.errors && (
                    <ul>
                        {this.state.errors.map((error, index) => (<li key={"error-" + index}>{error}</li>))}
                    </ul>
                )}
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: lang.name.error[current] }],
                    })(
                        <Input
                            placeholder={lang.name[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('telephone', {
                        rules: [{ required: false, message: lang.telephone.error[current] }],
                    })(
                        <Input
                            placeholder={lang.telephone[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: lang.emailAddress.error[current] }],
                    })(
                        <Input
                            type="email"
                            placeholder={lang.emailAddress[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('message', {
                        rules: [{required: true, message: lang.message.error[current]}],
                        initialValue: ''
                    })(
                        <TextArea
                            rows={4}
                            placeholder={lang.message[current]}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn btn-secondary" disabled={!this.state.allowSubmit}>
                        <span>{lang.submit[current]}</span>
                        <span className="icon arrow"></span>
                    </Button>
                </Form.Item>
            </Form>
        } else {
            content = <Location>
                {({location}) => {
                    let path = current === 'en' ? 'thank-you' : 'diolch';
                    navigate(location.pathname + path + '/');
                }}
            </Location>
        }

        return content;
    }
}

ContactForm.contextType = LocaleContext;

export default Form.create()(ContactForm);
